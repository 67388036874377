// extracted by mini-css-extract-plugin
export var addressContainer = "LocationShowContentMapTab__addressContainer__Ly9ze";
export var column = "LocationShowContentMapTab__column__Ir3N1";
export var container = "LocationShowContentMapTab__container__CtVqK";
export var flex = "LocationShowContentMapTab__flex__h3yQs";
export var flexColumn = "LocationShowContentMapTab__flexColumn__shQML";
export var gap1 = "LocationShowContentMapTab__gap1__NdFeg";
export var gap2 = "LocationShowContentMapTab__gap2__upp9h";
export var gap3 = "LocationShowContentMapTab__gap3__H1eJT";
export var gap4 = "LocationShowContentMapTab__gap4___3PrV";
export var gap5 = "LocationShowContentMapTab__gap5__a5Rpm";
export var mapContainer = "LocationShowContentMapTab__mapContainer__Az1JB";
export var mapElement = "LocationShowContentMapTab__mapElement__mhJsS";
export var row = "LocationShowContentMapTab__row__y4DR7";
export var title = "LocationShowContentMapTab__title__apYPq";